<template>
  <v-container>
    <v-row>
      <v-col class="d-block d-md-flex justify-center pt-2 pb-6 py-md-16">
        <v-card
          elevation="16"
          :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : 'lg'"
          :width="$vuetify.breakpoint.mdAndUp ? 750 : '100%'"
        >
          <v-card-title class="grey">
            <v-icon :size="$vuetify.breakpoint.mdAndUp ? '60' : '30'" left>
              {{ icons.mdiFileLock }}
            </v-icon>
            <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-body-1'">{{ $t('sozlesmelerim') }}</span>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-subheader>{{ $t('kullaniciVeUyelikSozlesmesi') }}</v-subheader>
            <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2 mb-9">
              <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                <div v-if="lang == 'tr'"><UyelikSozlesmesiTR /></div>
                <div v-if="lang == 'en'"><UyelikSozlesmesiEN /></div>
              </perfect-scrollbar>
            </v-card>

            <v-subheader>{{ $t('kvkAcikRizaBeyan') }}</v-subheader>
            <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2 mb-9">
              <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                <div v-if="lang == 'tr'"><AcikRizaBeyanTR /></div>
                <div v-if="lang == 'en'"><AcikRizaBeyanEN /></div>
              </perfect-scrollbar>
            </v-card>

            <v-subheader>{{ $t('kvkAcikRizaBeyan') }}</v-subheader>
            <v-card color="white" height="350px" class="rounded-lg pa-5 pr-2 mb-9">
              <perfect-scrollbar class="ps-user-notifications ps ps--active-y" :options="perfectScrollbarOptions">
                <div v-if="lang == 'tr'"><KullaniciAydinlatmaTR /></div>
                <div v-if="lang == 'en'"><KullaniciAydinlatmaEN /></div>
              </perfect-scrollbar>
            </v-card>

            <!-- 

            <v-list flat nav :three-line="$vuetify.breakpoint.mdAndDown" color="secondary" class="rounded-lg">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon color="white">
                      {{ icons.mdiDownloadCircle }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle class="white--text">
                      {{ $t('kvkBasvuru') }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-caption white--text"> {{ $t('indir') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { mdiDownloadCircle, mdiFileLock } from '@mdi/js'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import UyelikSozlesmesiEN from '@/views/sozlesmeler/UyelikSozlesmesiEN.vue'
import UyelikSozlesmesiTR from '@/views/sozlesmeler/UyelikSozlesmesiTR.vue'
import AcikRizaBeyanEN from '@/views/sozlesmeler/acikRizaBeyan/AcikRizaBeyanEN.vue'
import AcikRizaBeyanTR from '@/views/sozlesmeler/acikRizaBeyan/AcikRizaBeyanTR.vue'
import KullaniciAydinlatmaEN from '@/views/sozlesmeler/kullaniciAydinlatma/KullaniciAydinlatmaEN.vue'
import KullaniciAydinlatmaTR from '@/views/sozlesmeler/kullaniciAydinlatma/KullaniciAydinlatmaTR.vue'

export default {
  components: {
    PerfectScrollbar,
    UyelikSozlesmesiEN,
    UyelikSozlesmesiTR,
    AcikRizaBeyanEN,
    AcikRizaBeyanTR,
    KullaniciAydinlatmaEN,
    KullaniciAydinlatmaTR,
    AcikRizaBeyanEN,
    AcikRizaBeyanEN,
    KullaniciAydinlatmaEN,
    KullaniciAydinlatmaEN,
  },
  setup() {
    const lang = ref('')
    const perfectScrollbarOptions = {
      minScrollbarLength: 60,
      wheelPropagation: true,
    }
    onMounted(() => {
      localStorage.getItem('lang') === 'tr' ? (lang.value = 'tr') : (lang.value = 'en')
    })

    return {
      perfectScrollbarOptions,
      lang,
      icons: {
        mdiFileLock,
        mdiDownloadCircle,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
.overflow-contracts {
  overflow-y: scroll;
}
.ps-user-notifications {
  max-height: 300px;
  .ps__rail-y {
    display: block !important;
    opacity: 1 !important;
    .ps__thumb-y {
      opacity: 1 !important;
      background-color: #312d4b !important;
      width: 9px !important;
      height: 60px !important;
    }
  }
}
</style>
